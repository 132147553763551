<template>
  <v-container fluid>
    <v-card>
      <v-toolbar
        color="accent"
        dark
        flat
        dense
      >
        <v-toolbar-title>Outgoing Payment On Account</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          dark
          small
          text
          to="/banking/outgoing-payments/on-account"
        >
          <v-icon
            left
            dark
          >mdi-keyboard-backspace</v-icon>Back
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <!-- editor -->
        <editor
          :tenantData="tenantData"
          :currencies="currencies"
          :initial="dataItem"
          :editMode="editMode"
          @data="save"
          @draftData="saveDraft"
        ></editor>
        <!-- end -->

        <!-- <v-row>
          <v-col cols="9"></v-col>
          <v-col cols="3">
            <v-autocomplete
              style="margin-top: -80px;"
              @change="copyDocTo(docId)"
              color="primary"
              v-model="docId"
              :items="copyDocs"
              item-text="name"
              item-value="id"
              label="Copy To"
            ></v-autocomplete>
          </v-col>
        </v-row> -->
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Editor from "../_components/editor.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Editor,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  data: () => ({
    dataItem: { parent: false, vmp4: [], vpm1: [] },
    tenantData: {},
    currencies: [],
    ArInvoice: {},
    editMode: true,
    docId: null,
    copyDocs: [{ name: "A/R Credit Memo", id: 14 }],
  }),
  watch: {
    "$route.params.id": {
      handler: "getById",
      immediate: true,
    },
  },
  methods: {
    copyDocTo(id) {
      const BaseType = 13;
      const docId = this.dataItem.id;
      const copyToDoc = id;
      // cheeck iif A/R invoice
      if (copyToDoc == 14) {
        this.$router.push(`/ar-credit-memo/copy/${BaseType}/${docId}`);
      }
    },
    getById(val) {
      const self = this;
      this.$store
        .dispatch("get", `/outgoing_payment/${val}`)
        .then((res) => {
          self.dataItem = res.ResponseData;
          this.dataItem.vpm4 = res.ResponseData.vpm4;
          this.dataItem.vpm1 = res.ResponseData.vpm1;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    save(data) {
      const url = "/ar_invoice";
      const self = this;
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          console.log(res, "r");
          // self.$refs.snackbar.show("Item group created", "green");
          self.$router.push("/sales/ar-invoice");
        })
        .catch((err) => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    },
    saveDraft(data) {
      const url = "/drafts";
      const self = this;
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          console.log(res, "r");
          // self.$refs.snackbar.show("Item group created", "green");
          self.$router.push("/sales/ar-invoice");
        })
        .catch((err) => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    },
    getCurrencies() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/currency`)
        .then((res) => {
          self.currencies = res;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getCurrencies();
  },
};
</script>